<template>
  <div class="k-9sptci" v-loading="loading">
    <h4>{{ titlename }}·预选赛预测管理</h4>
    <!-- 表单 -->
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item class="team_status" label="领队UID" prop="uid">
        <el-input
          v-model="searchForm.leader_uid"
          maxlength="8"
          clearable
          placeholder="领队UID"
        ></el-input>
      </el-form-item>
      <el-form-item class="team_status" label="门派ID" prop="sect_id">
        <el-input
          v-model="searchForm.sect_id"
          maxlength="6"
          clearable
          placeholder="门派ID"
        ></el-input>
      </el-form-item>
      <el-form-item class="team_status" label="门派名称" prop="sect_name">
        <el-input
          v-model="searchForm.sect_name"
          clearable
          placeholder="门派名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="match_type != 'match_s4'"
        class="team_status"
        label="队伍资格"
        prop="status"
      >
        <el-select
          v-model="searchForm.team_seniority"
          placeholder="请选择"
          clearable
          @change="handleDepot()"
        >
          <el-option :value="2" label="替补"></el-option>
          <el-option :value="1" label="正赛"></el-option>
        </el-select>
      </el-form-item>
      <!-- s4之前 -->
      <el-form-item
        v-if="match_type != 'match_s4'"
        class="team_status"
        label="状态"
        prop="status"
      >
        <el-select
          v-model="searchForm.status"
          placeholder="请选择"
          @change="handleDepot()"
        >
          <el-option :value="0" label="正常"></el-option>
          <el-option :value="1" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <!-- s4之后 -->
      <el-form-item
        v-if="match_type === 'match_s4'"
        class="team_status"
        label="状态"
        prop="team_seniority"
      >
        <el-select
          v-model="searchForm.team_seniority"
          placeholder="请选择"
          @change="handleDepot()"
        >
          <el-option :value="''" label="全部"></el-option>
          <el-option :value="1" label="已加入正赛"></el-option>
          <el-option :value="2" label="预测"></el-option>
          <el-option :value="4" label="其他状态"></el-option>
        </el-select>
      </el-form-item>
      <div class="btn_container">
        <div class="btn_wraper">
          <el-form-item>
            <el-button type="success" @click="searchClick">查询</el-button>
          </el-form-item>
          <el-form-item v-if="match_type != 'match_s4'">
            <el-button type="primary" @click="addPointsRule"
              >押分规则</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="exportTeamToExcel"
              >导出数据</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 周 -->
    <el-tabs
      v-if="match_type === 'match_s4'"
      v-model="weeksTab"
      @tab-click="handleClick"
      type="card"
    >
      <el-tab-pane
        v-for="(item, index) in weeks"
        :label="item.label"
        :name="item.name"
        :key="index"
      >
      </el-tab-pane>
    </el-tabs>
    <!-- 表格 -->
    <el-table :data="dataTable" style="margin-top: 20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop.indexOf('member') == 0 || item.prop == 'alternate'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :align="item.align || 'left'"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <div
              v-if="item.formatter(scope.row) == null"
              style="color: red; width: 100%"
            >
              暂无
            </div>
            <div v-else style="width: 100%">
              {{ item.formatter(scope.row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'team_status'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :align="item.align || 'left'"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <div
              v-if="item.formatter(scope.row) != '满员'"
              style="color: red; width: 100%"
            >
              {{ item.formatter(scope.row) }}
            </div>
            <div v-else style="width: 100%">
              {{ item.formatter(scope.row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :align="item.align || 'left'"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:save`"
            @click.native.stop="handleEdit(scope.row)"
            >押分门派</perButton
          >
         
          <popconfirmButton
            v-if="scope.row.team_seniority === 4 "
            :perm="`match:save`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row, 0)"
            style="margin-left: 10px"
          ></popconfirmButton>
           <popconfirmButton
           v-else
            :perm="`match:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row, 1)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
// import importButton from "../../components/importButton";
// import importHistoryButton from "../../components/importHistoryButton";
export default {
  name: "team_list",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    titlename() {
      const matchTypeMap = {
        match_s1: "门派联赛第一赛季",
        match_s2: "门派联赛第二赛季",
        match_wpl2023: "城市联赛",
        match_s3: "门派联赛第三赛季",
        match_s4: "门派联赛第四赛季",
        match_s5: "门派联赛第五赛季",
      };
      return matchTypeMap[this.match_type] || "";
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.type_id = this.$route.query.type_id;
      },
      immediate: true,
    },
  },
  async mounted() {
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;
    this.type_id = this.$route.query.type_id;
    this.stage = this.$route.query.stage;
    this.match_type = this.$route.query.match_type;
    this.matchType = localStorage.getItem("matchType");
    if (this.match_type != "match_s4") {
      this.handleQuery();
    } else {
      this.get_stage_list();
    }
  },
  data() {
    return {
      weeksTab: "1",
      activeTab: "1",
      weeks: [{ label: "第1周", name: "1" }], //tab
      TabWeeks: [],
      stage: "",
      matchType: "",
      match_type: "", //s4之后
      ids: [],
      match_id: "",
      season_id: "",
      type_id: "",
      loading: false,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        leader_uid: "",
        sect_id: "",
        sect_name: "",
        team_seniority: "",
        status: null,
        week: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "sect_id",
          label: "门派ID",
          width: 200,
        },
        {
          prop: "sect_name",
          label: "门派名称",
          width: 200,
        },
        {
          prop: "leader_name",
          label: "领队",
          width: 300,
          formatter: (row) => {
            if (row.leader_uid && row.leader_uid.length) {
              return row.leader_name + "|" + row.leader_uid;
            }

            return "暂无";
          },
        },
        // {
        //   prop: "team_seniority",
        //   label: "队伍资格",
        //   width: 150,
        //   align: "center",
        //   formatter: (row) => {
        //     if (row.team_seniority == 2 || row.team_seniority == 3) {
        //       return "替补";
        //     } else {
        //       return "正赛";
        //     }
        //   },
        // },
        {
          prop: "rank",
          label: "最终积分",
          width: 150,
        },
        {
          prop: "status",
          label: "状态",
          width: 150,
          align: "center",
          formatter: (row) => {
            switch (row.team_seniority) {
              case 1:
                return "已进入正赛";
              case 2:
                return "预测中";
              case 3:
                return "预测中";
              case 4:
                return "其他情况";
              default:
                return "";
            }
          },
        },
      ],
    };
  },
  methods: {
    //s4之后的逻辑
    //点击tab
    handleClick() {
      this.get_stage_list();
    },
    //获取tab
    //tab周
    async get_stage_list() {
      const { data } = await this.$http.match.s4_stage_list({
        match_id: this.match_id,
        season_id: this.season_id,
        pageNum: "1",
        pageSize: "10",
      });
      this.TabWeeks = data.data;
      this.activeTab = data.data[this.weeksTab - 1]._id;
      this.handleQueryS4pro();

      if (this.weeks.length < data.data.length) {
        for (let i = 1; i < data.data.length; i++) {
          this.weeks.push({ label: `第${i + 1}周`, name: `${i + 1}` });
        }
      }
    },
    // 获取数据
    async handleQueryS4pro() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.match.charge_points_info({
        ...this.searchForm,
        match_id: this.match_id,
        pageNum,
        pageSize: this.pageSize,
        season_id: this.season_id,
        stage_id: this.activeTab,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    //s4之前的逻辑
    addPointsRule() {
      this.removeTabByPath(`/match/info/info_s1/pointsRule`);
      this.removeTabByPath(`/match/info/info_s1/pointsRule`);
      this.$router.push({
        path: `/match/info/info_s1/pointsRule`,
        query: {
          match_id: this.match_id,
          type_id: this.type_id,
          stage: this.stage,
        },
      });
    },
    handleEdit(row) {
      this.removeTabByPath(`/match/info/info_s1/escort_sects`);
      this.removeTabByPath(`/match/info/info_s1/escort_sects`);
      // match_id为赛事id  _id为当前队伍id

      this.$router.push({
        path: `/match/info/info_s1/escort_sects`,
        query: {
          _id: row._id,
          season_id: this.season_id,
          match_id: this.match_id,
          type_id: this.type_id,
          stage: this.stage,
        },
      });
    },
    async handleDelete(row, status) {
      console.log("🚀 ~ handleDelete ~ row:", row)
      this.loading = true;
      let { data, errorCode } = await this.$http.match.delete_charge_points(
        status,
        row._id
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });
      if (this.match_type === "match_s4") {
        this.handleQueryS4pro();
      } else {
        this.handleQuery();
      }
    },
    async handleRecovery(row, status) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.delete_charge_points(
        status,
        row._id
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });
      if (this.match_type === "match_s4") {
        this.handleQueryS4pro();
      } else {
        this.handleQuery();
      }
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      if (this.match_type.includes("match_s")) {
        this.handleQueryS4pro();
      } else {
        this.handleQuery();
      }
    },
    async exportTeamToExcel() {
      this.$http.download({
        url: "/admin/chargePoints/export_replace",
        method: "post",
        data: {
          match_id: this.match_id,
          stage_id: this.activeTab,
          ...this.searchForm,
        },
      });
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      if (this.match_type.includes("match_s")) {
        this.handleQueryS4pro();
      } else {
        this.handleQuery();
      }
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      if (this.match_type.includes("match_s")) {
        this.handleQueryS4pro();
      } else {
        this.handleQuery();
      }
    },
    async handleQuery() {
      if (this.match_type.includes("match_s")) {
        this.handleQueryS4pro();
      } else {
        const { pageNum } = this.pager;
        const { data, errorCode } = await this.$http.match.charge_points_info({
          ...this.searchForm,
          match_id: this.match_id,
          season_id: this.season_id,
          pageNum,
          pageSize: this.pageSize,
          stage_id: this.stage,
        });
        if (errorCode != "0000") return;
        this.dataTable = data.data;
        this.pager = data.pager;
      }
    },
    onTeamChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.members = data.members;
        current.alternate = data.alternate;
        current.leader_uid = data.leader_uid;
        current.leader_name = data.leader_name;
        current.leader_avatar = data.leader_avatar;
        current.team_status = data.team_status;
        current.status = data.status;
      } else {
        this.pager.pageNum = 1;
        if (this.match_type.includes("match_s")) {
          this.handleQueryS4pro();
        } else {
          this.handleQuery();
        }
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.$bus.$off("onTeamChanged", this.onTeamChanged);
    this.$bus.$on("onTeamChanged", this.onTeamChanged);
  },
  destroyed() {
    this.$bus.$off("onTeamChanged", this.onTeamChanged);
  },
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
.team_status {
  width: 160px;
}
.btn_wraper {
  display: flex;
}
.btn_wraper_bottom {
  margin-top: 10px;
}
.btn_container {
  display: flex;
  flex-direction: column;
}
</style>
